<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-notification dropdown-no-scroll mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          :key="bellKey"
          :badge="notificationCount > 0 ? notificationCount : ''"
          badge-classes="bg-danger"
          icon="BellIcon"
          size="21"
        />
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            Notifications
          </h4>
          <b-badge
            pill
            variant="light-primary"
          >
            {{ notificationCount }} New
          </b-badge>
        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        :key="dropdownKey"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >

        <div v-if="notifications.length > 0">
          <b-link
            v-for="notification in notifications"
            :key="notification.id"
            @click="onClickViewNotification(notification)"
          >
            <b-media>
              <template #aside>
                <b-avatar
                  size="32"
                  variant="light-success"
                />
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ notification.message }}
                </span>
              </p>
              <small class="notification-text">{{ __dateTimeFormatterPassedTime(notification.created) }}</small>
            </b-media>
          </b-link>
        </div>

        <div
          v-else
          class="media d-flex align-items-center"
        >
          <span class="font-weight-bold mr-auto mb-0">
            You have no unread notifications
          </span>
        </div>

      </vue-perfect-scrollbar>

      <!-- Cart Footer -->
      <li class="dropdown-menu-footer"><b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'notifications' }"
        @click="onReadAllClick()"
      >Read all notifications</b-button>
      </li>
    </b-nav-item-dropdown>
    <view-notification-modal ref="view_notification_modal" />
  </div>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import bellSound from '@/assets/notification/notification-bell.mp3'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import ViewNotificationModal from '@/components/Notification/ViewNotificationModal'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const notificationRepository = RepositoryFactory.get('notification')

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    ViewNotificationModal,
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications: [],
      perfectScrollbarSettings,
      bellKey: 1,
      dropdownKey: 1,
      audio: null,
      notificationCount: 0,
    }
  },
  mounted() {
    this.initNotificationLoop()
    this.audio = new Audio(bellSound)
  },
  methods: {
    async getAllNotifications() {
      const { data, meta } = (await notificationRepository.getAllNotifications(1, 'filter[read_at]=null')).data
      this.notificationCount = meta.total
      this.notifications = data
      this.bellKey += 1
      this.dropdownKey += 1
    },
    async initNotificationLoop() {
      await this.getAllNotifications()
      if (this.notificationCount > 0) {
        this.audio.play()
      }
      setTimeout(() => {
        this.initNotificationLoop()
      }, 300000)
    },
    onReadAllClick() {
      document.body.click()
    },
    onClickViewNotification(notification) {
      this.$refs.view_notification_modal.openModal(notification, 'navbar')
    },
  },
}
</script>
