export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    header: 'Order Manage',
  },
  {
    title: 'Add New Order',
    route: 'second-page',
    icon: 'PlusCircleIcon',
  },
  {
    title: 'My Orders',
    route: 'my-orders',
    icon: 'AlignJustifyIcon',
  },
  {
    title: 'Operations',
    route: 'client-operations',
    icon: 'CodepenIcon',
  },
  {
    header: 'Finance',
  },
  {
    title: 'Overview',
    route: 'merchant-overview',
    icon: 'TrelloIcon',
  },
  {
    title: 'My Invoices',
    route: 'my-invoice',
    icon: 'LayersIcon',
  },
  {
    title: 'My Rate Card',
    route: 'my-rate-card',
    icon: 'DollarSignIcon',
  },
  {
    header: 'Reports ',
  },
  {
    title: 'Reports',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Finance',
        route: 'finance-report',
        icon: '',
      },
      {
        title: 'Orders',
        icon: '',
        children: [
          {
            title: 'Delivery',
            route: 'delivery-orders',
            icon: '',
          },
          {
            title: 'Return',
            route: 'return-orders',
            icon: '',
          },
        ],
      },
    ],
  },
  {
    header: 'Settings ',
  },
  {
    title: 'Manage',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Businesses',
        route: 'businesses',
        icon: '',
      },
      {
        title: 'Users',
        route: 'users',
        icon: '',
      },
      {
        title: 'Roles',
        route: 'roles',
        icon: '',
      },
    ],
  },
  {
    title: 'Tickets',
    route: 'tickets',
    icon: 'BookIcon',
    permission: ['create-tickets'],
  },
  {
    title: 'Waybill Request',
    route: 'waybill-request',
    icon: 'FileMinusIcon',
    permission: ['show-waybill-request'],
  },
  {
    title: 'Settings',
    route: 'business-settings',
    icon: 'SettingsIcon',
  },
]
