<template>
  <div>
    <vue-identify-network
      class="mr-2"
      @network-speed="handleNetworkSpeed"
    >
      <template #unknown>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <b-tooltip
            target="unidentified_indicator"
            triggers="hover"
          >
            Unidentified Network
          </b-tooltip>
          <span
            id="unidentified_indicator"
          >
            <mdicon
              name="WifiCancel"
              size="25"
            />
          </span>
        </div>
      </template>
    </vue-identify-network>
    <div v-if="netSpeed < 2.0 && netSpeed != 0">
      <div class="d-flex flex-column justify-content-center align-items-center mr-1">
        <b-tooltip
          target="bad_indicator"
          triggers="hover"
          variant="danger"
        >
          Poor Network Speed - {{ netSpeed }} mbps
        </b-tooltip>
        <span
          id="bad_indicator"
        >
          <mdicon
            name="WifiAlert"
            size="25"
          />
        </span>
      </div>
    </div>
    <div v-if="netSpeed > 2.0">
      <div class="d-flex flex-column justify-content-center align-items-center mr-1">
        <b-tooltip
          target="good_indicator"
          triggers="hover"
          variant="success"
        >
          Good Network Speed - {{ netSpeed }} mbps
        </b-tooltip>
        <span
          id="good_indicator"
        >
          <mdicon
            name="Wifi"
            size="25"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BTooltip,
  },
  data() {
    return {
      netSpeed: 0.00,
    }
  },
  methods: {
    handleNetworkSpeed(val) {
      this.netSpeed = val
    },
  },
}
</script>
<style scoped>
#good_indicator .mdi svg {
  color: #58bfff;
}
#bad_indicator .mdi svg {
  color: #f5365c;
}
</style>
