<template>
  <p class="clearfix mb-0">
    <span class="float-md-right d-none d-md-block font-bold">Release Notes
      <a
        href="https://curfox.com/docs-category/release-notes/"
        target="_blank"
      >
        <feather-icon
          icon="BookOpenIcon"
          size="21"
          class="text-primary stroke-current"
        />
      </a>
    </span>

  </p>
</template>

<script>
export default {
}
</script>
