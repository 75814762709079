<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-notification dropdown-no-scroll mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          icon="PercentIcon"
          size="21"
          @click="handleButtonClick"
        />
      </template>

      <!-- Header -->
      <div class="my-container">
        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex justify-content-center">
            <h4 class="calculator-title mb-0">
              Rate Calculator
            </h4>
          </div>
        </li>
        <li>
          <!-- Origin City -->
          <b-form-group
            label=" Origin City"
            label-cols-md="4"
            label-for="h-originCity"
          >
            <ValidationProvider
              #default="{ errors }"
              name="h-c-originCity"
              vid="h-c-originCity"
              rules="required"
            >
              <v-select
                v-model="origin_city_id"
                :options="cityOptions"
                :reduce="option => option.id"
                label="name"
                placeholder="Select City"
                autocomplete="nope"
                @change="getCalculateDeliveryCharge"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </li>
        <li>
          <!-- Destination City -->
          <b-form-group
            label="Destination City"
            label-cols-md="4"
            label-for="city"
          >
            <ValidationProvider
              #default="{ errors }"
              name="h-c-city"
              vid="h-c-city"
              rules="required"
            >
              <v-select
                v-model="form.destination_city_id"
                :options="cityOptions"
                :reduce="option => option.id"
                label="name"
                placeholder="Select City"
                autocomplete="nope"
                @change="getCalculateDeliveryCharge"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </li>
        <li>
          <!-- Weight -->
          <b-form-group
            label="Weight"
            label-cols-md="4"
            label-for="weight"
          >
            <ValidationProvider
              #default="{ errors }"
              name="h-c-weight"
              vid="h-c-weight"
              rules="required"
            >
              <b-input-group>
                <b-form-input
                  id="weight"
                  v-model="form.weight"
                  type="number"
                  placeholder="1"
                  autocomplete="off"
                  step="0.01"
                  @change="getCalculateDeliveryCharge"
                />
                <b-input-group-append is-text>
                  <b>Kg</b>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </li>
        <li>
          <!-- Delivery charge -->
          <b-form-group
            label="Delivery Charge"
            label-cols-md="4"
            label-for="delivery_charge"
          >
            <strong><input
              id="delivery_charge"
              v-model="delivery_charge"
              type="text"
              class="form-control"
              disabled
              autocomplete="off"
            ></strong>
          </b-form-group>
        </li>
      </div>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BNavItemDropdown, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
// eslint-disable-next-line import/extensions
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')
const CalculatorRepository = RepositoryFactory.get('calculator')
const MerchantRepository = RepositoryFactory.get('merchant')
export default {
  components: {
    BNavItemDropdown,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      originCities: [],
      destinationCities: [],
      cityOptions: [],
      origin_city_id: '',
      destination_city_id: '',
      form: {},
      weight: '',
      merchant_id: 0,
      delivery_charge: '',
    }
  },
  methods: {
    async handleButtonClick() {
      await this.getCityList()
      await this.getMerchantBusinessSettingsDetails()
    },
    async getCityList() {
      try {
        this.cityOptions = localStorage.getItem('city_list') ? JSON.parse(localStorage.getItem('city_list')) : (await ResourceRepository.getCityListDropdownList()).data.data
        if (!localStorage.getItem('city_list')) localStorage.setItem('city_list', JSON.stringify(this.cityOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getMerchantBusinessSettingsDetails() {
      try {
        const data = localStorage.getItem('merchant_business_setting') ? JSON.parse(localStorage.getItem('merchant_business_setting')) : (await MerchantRepository.getMerchantBusinessSettingsDetails()).data.data
        if (!localStorage.getItem('merchant_business_setting')) localStorage.setItem('merchant_business_setting', JSON.stringify(data))
        this.merchant_id = data.merchant_id
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getCalculateDeliveryCharge() {
      try {
        this.loading = true
        const payload = {
          origin_city_id: this.origin_city_id,
          destination_city_id: this.form.destination_city_id,
          weight: this.form.weight,
          merchant_id: this.merchant_id,
        }
        const { data } = await CalculatorRepository.getCalculateDeliveryCharge(payload)
        this.delivery_charge = data['delivery charge']
      } catch (e) {
        this.convertAndNotifyError(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style>
.my-container {
  margin: 20px;
  padding: 10px;
}
</style>
