<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center d-flex flex-grow-1 d-lg-flex">
      <dark-Toggler class="d-block" />
      <div>
        <b-tooltip
          target="refresh_cache"
          triggers="hover"
          variant="primary"
        >
          Clear App Cache
        </b-tooltip>
        <div
          id="refresh_cache"
          @click="refreshCache"
        >
          <img
            class="cursor-pointer"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS1wYWludGJydXNoIj48cGF0aCBkPSJNMTguMzcgMi42MyAxNCA3bC0xLjU5LTEuNTlhMiAyIDAgMCAwLTIuODIgMEw4IDdsOSA5IDEuNTktMS41OWEyIDIgMCAwIDAgMC0yLjgyTDE3IDEwbDQuMzctNC4zN2EyLjEyIDIuMTIgMCAxIDAtMy0zWiIvPjxwYXRoIGQ9Ik05IDhjLTIgMy00IDMuNS03IDRsOCAxMGMyLTEgNi01IDYtNyIvPjxwYXRoIGQ9Ik0xNC41IDE3LjUgNC41IDE1Ii8+PC9zdmc+"
          >
        </div>
      </div>
      <div>
        <b-tooltip
          target="tracking_icon"
          triggers="hover"
          variant="primary"
        >
          Visit Tracking
        </b-tooltip>
        <a
          id="tracking-icon"
          :href="getTrackingLink()"
          target="_blank"
        >
          <img
            class="tracking-icon"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS1tYXAtcGluIj48cGF0aCBkPSJNMjAgMTBjMCA2LTggMTItOCAxMnMtOC02LTgtMTJhOCA4IDAgMCAxIDE2IDBaIi8+PGNpcmNsZSBjeD0iMTIiIGN5PSIxMCIgcj0iMyIvPjwvc3ZnPg=="
          >
        </a>
      </div>
      <div>
        <b-tooltip
          target="waybills"
          triggers="hover"
          variant="primary"
        >
          Waybill Request
        </b-tooltip>
        <div
          id="waybills"
          @click="handleCickForWaybill"
        >
          <feather-icon
            icon="FileMinusIcon"
            size="26"
            class="file-icon"
            style="margin-left:8px;"
          />
        </div>
      </div>
    </div>

    <div class="bookmark-wrapper business-title align-items-center flex-grow-1 d-none d-lg-flex">
      <!--      <b><p>{{ currentUser.merchant_name }}</p></b>-->
      <table>
        <tr><b class="nav-text">{{ form.company_name }}</b></tr>
      </table>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <network-speed />
      <notification-dropdown />
      <waybill />
      <cal-dropdown />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ currentUser ? currentUser.first_name : '' }} {{ currentUser ? currentUser.last_name : '' }}
            </p>
            <span class="user-status">{{ currentUser ? currentUser.user_name : '' }}</span>
            <span
              class="user-status"
              style="margin-top:4px; "
            >{{ currentUser ? currentUser.role.name : '' }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/4-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          to="business-settings"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="onClickLogout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BTooltip,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import NotificationDropdown from '@/@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import CalDropdown from '@/@core/layouts/components/app-navbar/components/CalDropdown.vue'
import NetworkSpeed from '@/@core/layouts/components/app-navbar/components/NetworkSpeed.vue'
import {
  mapGetters,
  mapActions,
} from 'vuex'
import { appEnv, tenant } from '@/constants/config'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BTooltip,
    NotificationDropdown,
    CalDropdown,
    DarkToggler,
    NetworkSpeed,
  },
  data() {
    return {
      merchant: '',
      form: {},
      appEnv,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  // eslint-disable-next-line vue/order-in-components
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    this.form = JSON.parse(localStorage.getItem('businessSettings'))
  },
  methods: {
    ...mapActions(['logout']),
    async onClickLogout() {
      // eslint-disable-next-line no-underscore-dangle
      this.__clearCache()
      await this.logout()
    },
    refreshCache() {
      // eslint-disable-next-line no-underscore-dangle
      this.__clearCache()
      window.location.reload()
    },
    getTrackingLink() {
      if (this.appEnv === 'local') {
        return 'http://localhost:3000'
      }
      if (this.appEnv === 'staging' || window.location.origin.split('.')[1] === 'merchant') {
        return window.location.origin.replaceAll('merchant', 'tracking')
      }
      // if url inlude curfox return below one
      if (window.location.host.includes('curfox')) {
        return `https://${tenant}.tracking.curfox.com`
      // eslint-disable-next-line no-else-return
      } else {
        // else replace office/merchant with tracking
        return window.location.origin.replaceAll('merchant', 'tracking')
      }
    },
    handleCickForWaybill() {
      this.$router.push('/waybill-request')
    },
  },
}
</script>
<style lang="css" scoped>

.tracking-icon{
  height:25px;
  width: 25px;
  margin-left:3px;
}
.calculator-icon{
  height:25px;
  width: 25px;
  margin-left:3px;
}
.business-title {
  justify-content: flex-start;
  font-size: 20px;
}
.file-icon{
  color: white;
}
</style>
