<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <div class="w-100 d-flex justify-content-between align-items-center">
      <b-col
        class="content-header-left mb-2 mt-1 mt-md-0"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col
            cols="12"
            class="d-flex align-items-center"
          >
            <h2 class="content-header-title float-left pr-1 mb-0">
              {{ $route.meta.pageTitle }}
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                    color="black"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in $route.meta.breadcrumb"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
            <span
              v-if="$route.meta.help"
            >
              <a
                :href="$route.meta.help"
                target="_blank"
              >
                <feather-icon
                  v-b-tooltip.hover.v-primary="'Click to view user guide'"
                  icon="HelpCircleIcon"
                  size="15"
                  class="cursor-pointer text-primary ml-1 ml-md-0"
                />
              </a>
            </span>
            <!-- <img src="/santa-sleigh.png" class="santa-sleigh" /> -->
          </b-col>
        </b-row>
      </b-col>
    </div>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'
import { appEnv } from '@/constants/config'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  data() {
    return {
      appEnv,
    }
  },
}
</script>
<style scoped>
.santa-sleigh {
  width: 100px;
  margin-left: 20px;
  animation: animate 5s ease-in-out infinite;
}
@keyframes animate {
  0%,100% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(15px);
  }
}
</style>
